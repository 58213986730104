import React, { Suspense, lazy } from "react"
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Loading from './component/Loading/Loading'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Provider } from "react-redux";
import { store } from "../src/redux/storeConfig/store";
import { blue, pink, yellow, green, grey } from '@material-ui/core/colors';
import ProductMerriweather from './asset/Merriweather-Bold.ttf';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const Merriweather = {
  fontFamily: 'Merriweather',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Merriweather'),
    local('Merriweather-Bold'),
    url(${ProductMerriweather}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const LazyApp = lazy(() => import("./App"))
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Merriweather',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [Merriweather],
      },
    },
    MuiButton: {
      root: {
        borderRadius: 60,
        fontWeight: 600
      }
    },
    MuiInputBase: {
      root: {
        borderRadius: 60,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 30
      }
    },
  },
  palette: {
    primary: {
      light: yellow[100],
      main: yellow[700],
      dark: yellow[900],
    },
    secondary: {
      main: yellow[200],
    },
    tertiary: {
      light: pink[100],
      main: pink[500],
      dark: pink[700],
    },
    quaternary: {
      light: blue[100],
      main: blue[500],
      dark: blue[700],
    },
    success: {
      light: green[100],
      main: green[500],
      dark: green[700],
    },
    white: {
      light: grey[100],
      main: grey[500],
      dark: grey[700],
    },
      bukalapak: {
          main: "#DD2153"
      },
      kemnaker: {
          main: "#F6F6F6"
      },
      tokopedia: {
          main: "#60BB55"
      },
  },
});

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_ID,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });
  
ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading />}>
          <LazyApp />
        </Suspense>
      </ThemeProvider>
    </Provider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
